

function Loader() {
    return(
        <div className="container">
	        <div className="loader"></div>
	        <div className="loader"></div>
	        <div className="loader"></div>
        </div>
    );

}

export default Loader;